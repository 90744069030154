@use '../../../sass/variables';
@import '../../../sass/functions';

.loading-screen {
    height: 60vh;
    background-color: variables.$proDarkBlue;
    overflow: hidden;
    padding: 0;
}

.homepage-hero {
    height: 60vh;
    overflow: hidden;
    padding: 0;

    &.initialised {
        height: auto;
    }

    .homepage-hero-slide {
        background-color: variables.$lightBlueAlt;
        background-position: center top;
        min-height: 70vh;
        padding-top: 80px;
        position: relative;
        width: 100%;

        .bg-cover {
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .inner-box {
            background-color: hexToString(variables.$lightBlueAlt, 0.8);
            padding: 30px;
            margin-bottom: 200px;
        }

        &.dark-theme {
            background-color: variables.$proDarkBlue;
            color: variables.$white;

            .inner-box {
                background-color: hexToString(variables.$proDarkBlue, 0.8);
            }
        }

        h1 {
            font-size: variables.$headerIncreasedSize;
            line-height: variables.$headerIncreasedLineHeight;
            margin-bottom: 25px;
        }

        h2 {
            font-size: variables.$subtitleIncreasedSize;
            font-family: variables.$headerFontStack;
            line-height: variables.$subtitleIncreasedLineHeight;
            margin-bottom: 25px;
        }

        &.text-center {
            h1,
            h2 {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.text-right {
            h1,
            h2 {
                margin-left: auto;
            }
        }

        .button-wrapper {
            padding-top: 25px;
        }
    }

    .slick-slider {
        .slick-list {
            transition: height 0.5s ease;
        }

        .slick-arrow {
            background-color: variables.$black;
            background-color: hexToString(variables.$black, 0.8);
            cursor: pointer;
            height: 103px;
            margin-top: -51.5px;
            opacity: 0.7;
            overflow: hidden;
            position: absolute;
            text-indent: -9999px;
            top: 50%;
            transition: opacity 0.3s, visibility 0.3s;
            visibility: visible;
            width: 61px;
            z-index: 1;

            &.slick-disabled {
                opacity: 0;

                &:hover,
                &:focus {
                    cursor: auto;
                    opacity: 0;
                }
            }

            &::before {
                font-size: 48px;
                line-height: 48px;
                padding-left: 3px;
                padding-top: 27px; 
                color: variables.$white;
            }

            &:focus,
            &:hover {
                background-color: variables.$black;
                border: 1px solid variables.$blue;
                opacity: 1;
            }
        }

        .slick-next {
            right: 25px;
        }

        .slick-prev {
            left: 25px;
        }
    }
}
